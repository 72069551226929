<template>
  <section class="section-block">
    <SectionTitle title="發票設定" @edit="modal.edit = true" />
    <el-form label-position="left" label-width="250px">
      <el-form-item v-for="field in displayData" :key="field.label" :label="field.label">
        <div class="text-[#5E5E5E]">
          <span>{{ field.value }}</span>
        </div>
      </el-form-item>
    </el-form>

    <InvoiceConfigEditModal
      v-if="modal.edit"
      :content="content"
      @close="modal.edit = false"
      @refresh="$emit('refresh')"
    />
  </section>
</template>

<script>
import { defineComponent, computed, reactive } from 'vue'
import SectionTitle from '@/components/Title/SectionTitle.vue'
import { invoice3rdPlatformConfig } from '@/config/pubApt'
import { get } from 'lodash'
import InvoiceConfigEditModal from './InvoiceConfigEditModal.vue'

export default defineComponent({
  name: 'InvoiceConfigBlock',
  components: { SectionTitle, InvoiceConfigEditModal },
  props: {
    content: { type: Object, default: () => ({}) },
  },
  emits: ['refresh'],
  setup (props) {
    const displayData = computed(() => {
      const config = props.content
      return [
        { label: '開放串接第三方發票', value: config.enable ? '開啟' : '關閉' },
        { label: '第三方串接發票', value: get(invoice3rdPlatformConfig, `${config.platform}.label`) || '尚未設置' },
        { label: 'MerchantID', value: get(config, 'invoiceApiConfig.merchantID') || '-' },
        { label: 'Hash Key', value: get(config, 'invoiceApiConfig.hashKey') || '-' },
        { label: 'Hash IV', value: get(config, 'invoiceApiConfig.hashIV') || '-' },
      ]
    })
    const modal = reactive({
      edit: false,
    })
    return { displayData, modal }
  },
})
</script>

<style lang="postcss" scoped>

</style>
