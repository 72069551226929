<template>
  <div>
    <el-form-item label="TapPay Partner Account" prop="config.tapPay.partnerAccount">
      <el-input
        v-model="syncForm.partnerAccount"
        placeholder="請輸入"
      />
    </el-form-item>
    <el-form-item label="TapPay Partner Key" prop="config.tapPay.partnerKey">
      <el-input
        v-model="syncForm.partnerKey"
        placeholder="請輸入"
      />
    </el-form-item>
    <el-form-item label="MerchantId" prop="config.tapPay.merchantId">
      <el-input
        v-model="syncForm.merchantId"
        placeholder="請輸入"
      />
    </el-form-item>
    <el-form-item label="appID" prop="config.tapPay.appID">
      <el-input
        v-model="syncForm.appID"
        placeholder="請輸入"
      />
    </el-form-item>
    <el-form-item label="appKey" prop="config.tapPay.appKey">
      <el-input
        v-model="syncForm.appKey"
        placeholder="請輸入"
      />
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: 'TapPayForm',
  props: {
    formData: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    syncForm: {
      get () { return this.formData },
      set (v) { return this.$emit('update', v) },
    },
  },
}
</script>

<style lang="postcss" scoped>

</style>
