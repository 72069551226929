<template>
  <section :v-loading="loading" class="settings-section section-block">
    <div>
      <SectionTitle title="付款方式設定" btn="新增" @edit="handlePayment('create')" />
      <el-table :data="displayData">
        <el-table-column label="付款方式名稱" prop="displayName" align="center" />
        <el-table-column label="付款方式種類" prop="type" align="center" />
        <el-table-column label="串接狀態" align="center">
          <template slot-scope="scope">
            <Tag :type="tagType(scope.row.verified)">
              {{ scope.row.verified ? '串接成功' : '串接失敗' }}
            </Tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" align="center">
          <template slot-scope="scope">
            <TableEditBtnGroup
              copyBtn="查看"
              :hideCopy="!scope.row.verified"
              :hideEdit="scope.row.verified"
              :hideDelete="scope.row.verified"
              @copy="handlePayment('view', scope.row)"
              @edit="handlePayment('edit', scope.row)"
              @delete="handlePayment('delete', scope.row)"
            />
          </template>
        </el-table-column>
      </el-table>
    </div>
    <EditPubAptPaymentDialog
      v-if="showEditPaymentDialog"
      :selectPayment="selectPayment"
      :type="paymentDialogType"
      @refresh="refresh"
      @close="showEditPaymentDialog = false"
    />
    <ViewPubAptPaymentDialog
      v-if="showViewPaymentDialog"
      :selectPayment="selectPayment"
      :pubAptPaymentTypes="pubAptPaymentTypes"
      @close="showViewPaymentDialog = false"
    />

    <DeleteDialog
      v-if="deleteDialog"
      title="提醒"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      @close="deleteDialog = false"
      @delete="deletePubAptPaymentConfig"
    />
  </section>
</template>

<script>
import { defineComponent, ref, computed } from 'vue'
import DeleteDialog from '@/components/Dialog/DeleteDialog.vue'
import EditPubAptPaymentDialog from './EditPubAptPaymentDialog.vue'
import ViewPubAptPaymentDialog from './ViewPubAptPaymentDialog.vue'
import { pubAptPaymentTypes } from '@/config/pubApt'
import { DeletePubAptPaymentConfig } from '@/api/pubApt'
import { useShop } from '@/use/shop'
import { map, get } from 'lodash'

export default defineComponent({
  name: 'PaymentTypeSettings',
  components: { EditPubAptPaymentDialog, ViewPubAptPaymentDialog, DeleteDialog },
  props: {
    content: { type: Array, default: () => [] },
  },
  setup (props, { emit }) {
    const { shopId } = useShop()
    const loading = ref(false)
    const deleteDialog = ref(false)
    const showEditPaymentDialog = ref(false)
    const showViewPaymentDialog = ref(false)
    const paymentDialogType = ref('create')
    const displayData = computed(() => {
      return map(get(props, 'content'), (item) => {
        return {
          ...item,
          type: get(pubAptPaymentTypes, get(item, 'config.type')).name,
        }
      })
    })
    const selectPayment = ref(null)
    const handlePayment = async (type, row) => {
      paymentDialogType.value = type
      if (type === 'delete') {
        selectPayment.value = row
        deleteDialog.value = true
      }
      if (type === 'create') {
        selectPayment.value = null
        showEditPaymentDialog.value = true
      }
      if (type === 'edit') {
        selectPayment.value = row
        showEditPaymentDialog.value = true
      }
      if (type === 'view') {
        selectPayment.value = row
        showViewPaymentDialog.value = true
      }
    }
    const deletePubAptPaymentConfig = async () => {
      deleteDialog.value = false
      const [, err] = await DeletePubAptPaymentConfig({
        shopId: shopId.value,
        id: get(selectPayment, 'value.id'),
      })
      if (err) return window.$message.error(err)
      window.$message.success('更新成功！')
      await refresh()
    }
    const tagType = (val) => {
      let type = 'info'
      // if(! val) type = 'danger'
      if (val) type = 'action'
      return type
    }
    const refresh = async () => {
      emit('refresh')
    }
    return {
      tagType,
      refresh,
      loading,
      pubAptPaymentTypes,
      // pubAptPayments,
      displayData,
      handlePayment,
      deletePubAptPaymentConfig,
      showEditPaymentDialog,
      showViewPaymentDialog,
      deleteDialog,
      paymentDialogType,
      selectPayment,
    }
  },
})
</script>
<style></style>
