<template>
  <div class="sales-parameter">
    <section class="section-block">
      <SectionTitle title="店家資訊" btn="前往分店設定" @edit="router.push({ name: 'BranchShopSetting' })" />
      <div>
        <el-form label-position="left" label-width="250px">
          <el-form-item label="店家名稱">
            <div class="secret-text collapse">
              <span>{{ shopName || '-' }}</span>
            </div>
          </el-form-item>
          <el-form-item label="電話號碼">
            <div class="secret-text collapse">
              <span>{{ shopPhone || '-' }}</span>
            </div>
          </el-form-item>
          <el-form-item label="聯絡地址">
            <div class="secret-text collapse">
              <span>{{ shopAddress || '-' }}</span>
            </div>
          </el-form-item>
          <el-form-item label="Email">
            <div class="secret-text collapse">
              <span>{{ shopEmail ? shopEmail : '尚未設定' }}</span>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </section>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive } from 'vue'
import SectionTitle from '@/components/Title/SectionTitle'
import { useRouter } from 'vue-router/composables'
import { useShop } from '@/use/shop'
export default defineComponent({
  name: 'StoreInfo',
  components: { SectionTitle },
  setup () {
    const router = useRouter()
    const { shopName, shopPhone, shopAddress, shopEmail } = useShop()
    return {
      router,
      shopName,
      shopPhone,
      shopAddress,
      shopEmail,
    }
  },
})
</script>

<style scoped lang="scss">
.secret-text,
.note {
  // width: 100px;
  font-weight: 400;
  @apply text-gray-80;
  letter-spacing: 1px;
}
</style>
