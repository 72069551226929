<template>
  <div class="sales-parameter">
    <section class="section-block">
      <SectionTitle title="預約確認提示設定" @edit="confirmReminderSettingDialog = true" />
      <el-form label-position="left" label-width="250px">
        <el-form-item label="是否開啟預約確認提示：">
          <span class="text-info">{{ config.enable ? '開啟' : '關閉' }}</span>
        </el-form-item>
        <el-form-item label="標題名稱：">
          <span class="text-info">{{ config.title || '尚未設定' }}</span>
        </el-form-item>
        <el-form-item label="內容：">
          <span class="text-info">{{ config.content ? '已設置' : '尚未設定' }}</span>
        </el-form-item>
      </el-form>
    </section>

    <!-- 連結設定 Dialog -->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="confirmReminderSettingDialog"
      title="編輯預約確認設定"
      @close="resetForm"
    >
      <el-form
        ref="form"
        :model="formData"
        :rules="formRules"
        label-position="top"
      >
        <el-form-item label="是否開啟預約確認提示：" prop="enable">
          <el-switch
            v-model="formData.enable"
            active-text="開啟"
            inactive-text="關閉"
          />
        </el-form-item>
        <el-form-item label="標題名稱" prop="title">
          <el-input
            v-model="formData.title"
            placeholder="請輸入"
            :disabled="!formData.enable"
            maxlength="12"
            show-word-limit
          />
        </el-form-item>
        <el-form-item ref="content" label="內容" prop="content">
          <div :class="{'editor-diabled': !formData.enable}">
            <quillEditor
              v-model="formData.content"
              :options="editorOption"
              :disabled="!formData.enable"
            />
          </div>
        </el-form-item>
      </el-form>

      <div slot="footer">
        <el-button plain @click="confirmReminderSettingDialog = false">取消</el-button>
        <el-button type="primary" @click="updateConfirmReminder">儲存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, set, nextTick, watch } from 'vue'
import SectionTitle from '@/components/Title/SectionTitle'
import quillMixin from '@/mixin/quill'

import { noEmptyRules } from '@/validation'
import formUtils from '@/utils/form'
import { get, cloneDeep } from 'lodash'
import { useEditor } from '@/use/editor'

export default defineComponent({
  name: 'ConfirmReminderSettings',
  components: { SectionTitle },
  mixins: [quillMixin],
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const { editorOption } = useEditor()
    const config = ref({})
    const confirmReminderSettingDialog = ref(false)
    const formData = ref({
      enable: false,
      title: '',
      content: '',
    })
    const formRules = reactive({
      enable: [noEmptyRules()],
      title: [noEmptyRules()],
      content: [noEmptyRules()],
    })
    const form = ref(null)
    const updateConfirmReminder = async () => {
      if (!(await formUtils.checkForm(form.value))) return
      emit('updateConfirmReminder', formData.value)
      confirmReminderSettingDialog.value = false
    }
    const resetForm = () => {
      formUtils.resetForm(form.value)
    }
    const syncData = async () => {
      await nextTick()
      set(formData, 'value', cloneDeep(get(props, 'content')))
      set(config, 'value', get(props, 'content'))
    }
    watch(props, async () => {
      await syncData()
    })
    return {
      config,
      confirmReminderSettingDialog,
      formData,
      formRules,
      updateConfirmReminder,
      form,
      resetForm,
      editorOption,
    }
  },
})
</script>

<style lang="scss">
.el-switch__label span {
  font-size: 16px;
}
</style>

<style scoped lang="scss">
// section {
//   margin-top: 2rem;
//   margin-bottom: 50px;
// }
::v-deep .el-radio__label {
  @apply flex
}
.editor-diabled {
  @apply bg-info-light opacity-50 cursor-not-allowed pointer-events-none;
}
.secret-text,
.note {
  // width: 100px;
  font-weight: 400;
  @apply text-gray-80;
  letter-spacing: 1px;
}

.collapse {
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.copy-block {
  @apply flex items-center overflow-hidden max-w-[440px];

  a {
    @apply overflow-hidden text-gray-80;
    text-decoration: none;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.copy-btn {
  @apply h-[30px] rounded-full py-[3px] ml-[30px] px-[30px];
}
</style>
